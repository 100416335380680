<template>
  <div class="relative" @click="dismissModal">
    <trac-loading v-if="isLoading" />

    <main class="md:ml-12 mr-4 md:mr-0" v-if="invoice">
      <trac-back-button>{{
        updateInvoiceFlag ? 'Invoice Details' : 'Invoices'
      }}</trac-back-button>

      <!-- <trac-modal :cancel="false" v-if="alertPaymentMethodModalCtrl">
        <div class="mt-4">
          <span class="font-bold text-base">Hey there!</span>
          <p class="text-xs mt-4">
            Please note that you will be charged 1.5% of your payment by our
            service provider if you proceed with this option.
          </p>
        </div>
        <div class="mt-8">
          <trac-button
            class="block w-full"
            @button-clicked="alertPaymentMethodModalCtrl = false"
            >Ok</trac-button
          >
        </div>
      </trac-modal> -->

      <trac-modal
        v-if="addMilestoneModalCtrl"
        class="max-w-sm w-full"
        @close="dismissAddMilestoneModal"
      >
        <span class="font-bold text-sm">Add Milestone</span>
        <trac-validation-observer slim v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(addMiletone)">
            <div class="my-6">
              <trac-validation-provider
                name="milestone name"
                rules="required"
                v-slot="{ errors }"
              >
                <trac-input
                  placeholder="Name"
                  v-model.trim="createMilestone.title"
                ></trac-input>
                <trac-input-error v-if="errors[0]">{{
                  errors[0]
                }}</trac-input-error>
              </trac-validation-provider>
            </div>

            <!--  -->
            <div class="my-6">
              <trac-validation-provider
                name="milestone amount"
                rules="required|positive|greaterthanzero"
                v-slot="{ errors }"
              >
                <trac-input
                  placeholder="Amount"
                  v-model.trim.number="createMilestone.amount"
                ></trac-input>
                <trac-input-error v-if="errors[0]">{{
                  errors[0]
                }}</trac-input-error>
              </trac-validation-provider>
            </div>
            <!--  -->
            <trac-dropdown-exteneded
              :neededProperty="''"
              :selector="createMilestoneDueDate"
              :inputDate="new Date(createMilestone.due_date)"
              :customDropdown="true"
              class=""
              @selectDate="
                createMilestone.due_date = new Date($event).toISOString()
              "
            ></trac-dropdown-exteneded>

            <div class="mt-8">
              <trac-button class="ml-4" type="submit">{{
                createMilestone.client_id ? 'Update' : 'Confirm'
              }}</trac-button>
            </div>
          </form>
        </trac-validation-observer>
      </trac-modal>

      <trac-modal
        v-if="sendInvoiceModalCtrl && currentUserBusinessDetails"
        class="max-w-sm w-full"
        @close="sendInvoiceModalCtrl = false"
      >
        <span class="font-bold text-sm">Send Invoice</span>
        <trac-validation-observer slim v-slot="{ handleSubmit }">
          <form class="mt-10" @submit.prevent="handleSubmit(sendInvoice)">
            <trac-input
              class="my-6"
              placeholder="From"
              disabled
              v-model.trim="currentUserBusinessDetails.email"
            ></trac-input>
            <trac-validation-provider
              name="emails"
              rules="required"
              v-slot="{ errors }"
            >
              <trac-input
                class="my-6"
                placeholder="To (emails)"
                v-model.trim="sendInvoiceInfo.recipients"
              ></trac-input>
              <trac-input-error v-if="errors[0]">{{
                errors[0]
              }}</trac-input-error>
            </trac-validation-provider>
            <trac-input
              class="my-6"
              placeholder="Subject"
              v-model.trim="sendInvoiceInfo.subject"
            ></trac-input>
            <trac-textarea
              class="my-6"
              placeholder="Message"
              v-model.trim="sendInvoiceInfo.message"
            ></trac-textarea>

            <div class="my-6 font-semibold text-sm">
              <trac-toggle
                @newval="sendInvoiceInfo.sendToMyself = !$event"
                :active="sendInvoiceInfo.sendToMyself"
                v-model="sendInvoiceInfo.sendToMyself"
                >Send a copy to myself</trac-toggle
              >
            </div>
            <div class="flex justify-end">
              <trac-button
                class="ml-4"
                @button-clicked="sendInvoiceModalCtrl = false"
                >Cancel</trac-button
              >
              <trac-button class="ml-4" type="submit">Confirm</trac-button>
            </div>
          </form>
        </trac-validation-observer>
      </trac-modal>

      <trac-modal
        v-if="selectInvoiceItemsTypeModalCtrl"
        class="max-w-sm w-full"
        @close="selectInvoiceItemsTypeModalCtrl = false"
      >
        <span class="font-bold text-sm">Select Product Type</span>
        <div class="mt-10">
          <button
            class="w-full bg-transparent text-xs hover:bg-primaryBlue border-none p-3 outline-none hover:text-white"
            @click="showOneOffForm"
          >
            One-off item
          </button>
          <button
            data-test="add-product-from-library"
            @click="showLiraryProducts"
            class="drop-down-hold w-full bg-transparent text-xs hover:bg-primaryBlue border-none p-3 outline-none hover:text-white"
          >
            Add product from library
          </button>
        </div>
      </trac-modal>

      <trac-modal
        v-if="productVariantModalCtrl"
        class="max-w-sm w-full"
        @close="productVariantModalCtrl = false"
      >
        <span class="font-bold text-sm">Select product variant</span>
        <div class="mt-10">
          <div class="p-2 bg-gray-300">
            <input
              class="w-full p-2 text-xs"
              type="text"
              @input="searchVariant"
              placeholder="Search product"
            />
          </div>
          <button
            @click="addProductToCart(variant)"
            class="w-full"
            v-for="(variant, index) of filteredVarieties"
            :key="index"
          >
            <div
              class="flex justify-between items-center w-full p-3 hover:bg-gray-200"
            >
              <p class="text-xs">{{ variant.title }}</p>
              <span class="text-xs text-gray-600">{{
                variant.salePrice | formatPrice
              }}</span>
            </div>
          </button>
        </div>
      </trac-modal>

      <trac-modal
        data-test="one-off-item"
        v-if="oneOffItemModalCtrl"
        class="max-w-sm w-full"
        @close="oneOffItemModalCtrl = false"
      >
        <trac-validation-observer slim v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(addOneOffItem)" class="mt-2">
            <span class="font-bold text-sm">Add One Off Product</span>
            <trac-validation-provider
              name="product name"
              rules="required"
              v-slot="{ errors }"
            >
              <trac-input
                data-test="add-one-off-item-product-name"
                v-model="oneOffProduct.name"
                placeholder="Product Name"
                class="mt-4"
              />
              <trac-input-error v-if="errors[0]">{{
                errors[0]
              }}</trac-input-error>
            </trac-validation-provider>

            <trac-validation-provider
              name="price"
              rules="required|positive|greaterthanzero"
              v-slot="{ errors }"
            >
              <trac-input
                v-model.number="oneOffProduct.unit_price"
                placeholder="Unit Price"
                data-test="unit-price"
                class="mt-4"
              />
              <trac-input-error v-if="errors[0]">{{
                errors[0]
              }}</trac-input-error>
            </trac-validation-provider>

            <trac-validation-provider
              rules="required|positive|greaterthanzero"
              name="quantity"
              v-slot="{ errors }"
            >
              <trac-input
                v-model.number="oneOffProduct.quantity"
                placeholder="Quantity"
                data-test="quantity-button"
                class="mt-4"
              />
              <trac-input-error v-if="errors[0]">{{
                errors[0]
              }}</trac-input-error>
            </trac-validation-provider>

            <div class="flex justify-end mt-8">
              <p class="text-gray-700">
                Total:
                {{
                  (oneOffProduct.unit_price * oneOffProduct.quantity)
                    | formatPrice
                }}
              </p>
            </div>
            <trac-button
              data-test="confirm-button"
              class="mt-6 w-full"
              buttonType="submit"
              >Confirm</trac-button
            >
          </form>
        </trac-validation-observer>
      </trac-modal>

      <ul class="flex-col mt-12 mb-8">
        <li>Invoice</li>
        <li class="font-bold mt-4 text-sm">
          {{ invoice._id ? 'Edit' : 'New' }} Invoice
        </li>
      </ul>
      <form @submit.prevent="">
        <div class="bg-white px-8 py-8 w-full rounded-lg big-shadow">
          <div class="divide-y divide-gray-400">
            <div class="bg-accentLight px-8 py-12 w-full mb-8 rounded-lg">
              <div
                class="flex flex-col md:flex-row items-center justify-between"
                v-if="currentUserBusinessDetails"
              >
                <div class="flex-column md:w-2/12">
                  <img :src="currentUserBusinessDetails.logo" />
                </div>
                <div
                  class="flex justify-center md:w-8/12 text-center my-4 md:my-0"
                >
                  <input
                    id="inputFocus"
                    type="text"
                    @blur="setInvoiceTitle"
                    class="text-primaryBlue bg-transparent border-none font-bold text-base uppercase text-center w-1/6 outline-none"
                    v-model="invoice.title"
                    placeholder="Invoice Title..."
                    disabled
                  />
                  <img
                    data-test="edit-icon-button"
                    @click="triggerFocusInput"
                    src="./../../assets/svg/edit.svg"
                    width="20"
                    alt=""
                    class="cursor-pointer"
                  />
                </div>
                <div class="flex-column md:w-2/12 text-left">
                  <div class="float-right">
                    <span class="font-semibold text-primaryBlue text-xl">{{
                      currentUserBusinessDetails.name
                    }}</span>
                    <div>
                      <ul class="text-primaryGray font-medium text-sm">
                        <li class="text-xs">
                          {{ currentUserBusinessDetails.address.street }}
                        </li>
                        <li class="text-xs">
                          {{ currentUserBusinessDetails.address.city }}
                        </li>
                        <li class="text-xs">
                          {{ currentUserBusinessDetails.address.state }}
                        </li>
                        <li class="text-xs">
                          {{ currentUserBusinessDetails.address.country }}
                        </li>
                        <li class="text-xs">
                          {{ currentUserBusinessDetails.phone }}
                        </li>
                        <li class="text-primaryBlue underline">
                          <span
                            class="cursor-pointer"
                            @click="openBusinessPortal"
                            >Edit Business Details</span
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-8 flex flex-col md:flex-row">
              <div class="flex-column mt-8 md:w-4/12">
                <trac-custom-header>
                  <template slot="leading">Customer</template>
                  <template slot="trailing"> Details</template>
                </trac-custom-header>
                <div class="mr-8">
                  <adv-dropdown
                    :module="'customers'"
                    data-test="select-customer"
                    :string-fields="['first_name', 'last_name']"
                    :add-record-page="'AddCustomer'"
                    @on-selection="selectCustomer"
                    @add-record-path="gotoPage"
                    class="p-1.5"
                  />
                  <!-- <trac-dropdown-exteneded
                    class="absolute"
                    :neededProperty="'customerFullName'"
                    :selector="
                      updateInvoiceFlag
                        ? customer.first_name + ' ' + customer.last_name
                        : 'Select Customer'
                    "
                    :options="sortCustomersNames"
                    :shouldFilter="true"
                    :addProp="true"
                    @optionSelected="selectCustomer($event)"
                    @filter-list="filterCustomersList"
                    @add-prop="addCustomerOnTheFly"
                  ></trac-dropdown-exteneded> -->
                </div>

                <div class="text-xs mt-4" v-if="customer">
                  <p v-if="customer.phone" class="my-1">
                    <span class="text-gray-700">Phone:</span>
                    {{ customer.phone }}
                  </p>
                  <p v-if="customer.email" class="my-1">
                    <span class="text-gray-700">Email:</span>
                    {{ customer.email }}
                  </p>
                  <!-- <p v-if="customer.address.street === '' && customer.address.city === '' && customer.address.state === '' && customer.address.country === ''" class="my-1"><span class="text-gray-700">Address:</span> {{ customer.address.street }} {{ customer.address.city }} {{ customer.address.state }} {{ customer.address.country }}</p> -->
                  <p class="my-1">
                    <span class="text-gray-700">Last Active:</span>
                    {{
                      moment(customer.last_active_date).format('Do MMM YYYY')
                    }}
                  </p>
                </div>
              </div>
              <div class="flex-column mt-8 md:w-8/12 border-l pl-8">
                <trac-custom-header>
                  <template slot="leading">Invoice</template>
                  <template slot="trailing"> Details</template>
                </trac-custom-header>
                <div class="grid md:flex gap-y-8 flex-row">
                  <div class="flex-column md:w-6/12 mr-8">
                    <p class="text-xs text-gray-700 mb-2">Select Store:</p>
                    <trac-dropdown-exteneded
                      data-test="select-store"
                      :neededProperty="'name'"
                      :options="stores"
                      :selector="
                        invoice._id ? invoice.store_name : stores[0].name
                      "
                      @optionSelected="selectStoreOption($event)"
                    ></trac-dropdown-exteneded>

                    <div class="mt-4">
                      <p class="text-xs text-gray-700 mb-2">Discount Type:</p>
                      <trac-dropdown-exteneded
                        :neededProperty="'name'"
                        :options="discountTypes"
                        @optionSelected="selectDiscountType($event)"
                        :selector="
                          invoice._id
                            ? invoice.invoiceDiscountType.name
                            : discountTypes[0].name
                        "
                      ></trac-dropdown-exteneded>

                      <!-- <trac-input id="trac-input-percentage" class="" placeholder="Percentage"
                      v-model.number="invoice.discountPercentage"
                      @input="invoice.invoiceDiscountType = 'PERCENTAGE'; updateDiscountInputs($event);"
                      ></trac-input> -->
                    </div>

                    <p class="text-xs text-gray-700 mb-2 mt-4">
                      Invoice Terms:
                    </p>
                    <trac-dropdown-exteneded
                      data-test="invoice-terms"
                      :neededProperty="'description'"
                      class=""
                      :selector="
                        !invoice.type.description ||
                        invoice.type.description === ''
                          ? 'Select Terms'
                          : invoice.type.description
                      "
                      :options="invoiceTerms"
                      @optionSelected="selectTermsOption($event)"
                    ></trac-dropdown-exteneded>
                  </div>
                  <div class="flex-column md:w-6/12 mr-8 relative">
                    <p class="text-xs text-gray-700 mb-2">Invoice Date:</p>
                    <trac-dropdown-exteneded
                      data-test="invoice-date"
                      :neededProperty="''"
                      :selector="invoiceDateSeleced"
                      :customDropdown="true"
                      class=""
                      :inputDate="new Date(invoice.created_at)"
                      @selectDate="invoice.created_at = $event"
                    ></trac-dropdown-exteneded>

                    <!-- <div class="mt-2">
                      <p class="text-xs text-gray-700 mb-2">Amount:</p>
                      <trac-input class="" placeholder="Enter number"
                      v-model.number="invoice.discountAmount"
                      @input="invoice.discountPercentage = ''; invoice.invoiceDiscountType = 'AMOUNT'"
                      ></trac-input>
                    </div> -->

                    <div class="mt-4">
                      <p class="text-xs text-gray-700 mb-2">Discount:</p>
                      <trac-input
                        id="trac-input-amount"
                        class=""
                        placeholder="Enter number"
                        v-model.number="discountAmount"
                        :disabled="isDiscountFieldDisabled"
                      ></trac-input>
                    </div>
                    <p class="text-xs text-gray-700 mb-2 mt-4">Due Date:</p>
                    <trac-dropdown-exteneded
                      data-test="due-date"
                      :neededProperty="''"
                      :selector="dueDateSelected"
                      :customDropdown="true"
                      :inputDate="new Date(invoice.due_date)"
                      @selectDate="invoice.due_date = $event"
                      class=""
                    ></trac-dropdown-exteneded>
                  </div>
                </div>
              </div>
            </div>

            <div class="mt-8 pt-6">
              <trac-custom-header>
                <template slot="leading">Products And</template>
                <template slot="trailing"> Service(s)</template>
              </trac-custom-header>
              <div class="border rounded-lg relative">
                <div class="overflow-x-scroll">
                  <table class="w-full table-auto">
                    <thead class="bg-blue-100">
                      <tr
                        class="text-left text-xs mt-3 font-semibold uppercase"
                      >
                        <th class="md:w-7/12 py-3 px-4 whitespace-no-wrap pl-8">
                          Item
                        </th>
                        <th class="py-3 px-4 whitespace-no-wrap text-center">
                          Quantity
                        </th>
                        <th class="py-3 px-4 whitespace-no-wrap">Cost</th>
                        <th class="py-3 px-4 whitespace-no-wrap">Amount</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, index) in invoice.items"
                        :key="index"
                        class="text-left text-xs my-3"
                        :class="index % 2 === 0 ? '' : 'bg-gray-100'"
                      >
                        <td class="whitespace-no-wrap py-4 px-4 pl-8">
                          {{ item.name }}
                        </td>
                        <td class="whitespace-no-wrap py-4 px-4">
                          <div class="grid grid-cols-3">
                            <div class="flex justify-center">
                              <div class="p-3" v-if="item.client_id"></div>
                              <img
                                v-else
                                class="cursor-pointer"
                                @click="handleQty('increase', item)"
                                src="../../assets/svg/add.svg"
                              />
                            </div>
                            <!-- <span class="py-2"> {{ item.quantity }}</span> -->
                            <div class="flex justify-center">
                              <input
                                disabled
                                v-if="item.client_id"
                                class="text-center w-full bg-transparent"
                                type="number"
                                @input="inputInvoiceItemQty($event, item)"
                                v-model.number="item.quantity"
                              />
                              <input
                                v-else
                                class="text-center w-full"
                                type="number"
                                :disabled="
                                  item.quantity >=
                                  specificProductStoreStock(item).stockQuantity
                                "
                                @input="inputInvoiceItemQty($event, item)"
                                v-model.number="item.quantity"
                              />
                            </div>
                            <div class="flex justify-center">
                              <div class="p-3" v-if="item.client_id"></div>
                              <img
                                v-else
                                class="cursor-pointer"
                                @click="handleQty('decrease', item)"
                                src="../../assets/svg/minus.svg"
                              />
                            </div>
                          </div>
                        </td>
                        <td class="whitespace-no-wrap py-4 px-4">
                          {{ item.unit_price | formatPrice }}
                        </td>
                        <td class="whitespace-no-wrap py-4 px-4">
                          {{ (item.quantity * item.unit_price) | formatPrice }}
                        </td>
                        <td>
                          <div class="" style="min-width: 30px">
                            <img
                              class="cursor-pointer"
                              src="../../assets/svg/close_btn.svg"
                              alt=""
                              @click="removeProduct(item)"
                            />
                          </div>
                        </td>
                      </tr>
                      <tr class="">
                        <td class="pl-8 cursor-pointer py-3">
                          <span
                            data-test="add-new-item-button"
                            @click="selectInvoiceItemsTypeModalCtrl = true"
                            class="text-xs font-bold text-primaryBlue uppercase whitespace-no-wrap"
                            >Add New Item +</span
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div
                  class="drop-down-hold absolute w-full bg-white border mt-1 z-10"
                  v-if="productInLibraryDropdown"
                >
                  <div class="drop-down-hold p-2 bg-gray-300">
                    <input
                      class="drop-down-hold w-full p-2 text-xs"
                      type="text"
                      placeholder="Search product"
                      @input="searchProducts"
                    />
                  </div>
                  <div class="overflow-scroll" style="max-height: 300px">
                    <button
                      @click="addProductToCart(product)"
                      class="w-full"
                      v-for="(product, index) of filteredProducts"
                      :key="index"
                    >
                      <div
                        class="flex justify-between items-center w-full p-3 hover:bg-gray-200"
                      >
                        <p class="text-xs">{{ product.title }}</p>
                        <span
                          v-if="product.hasVariant"
                          class="text-xs text-gray-600"
                          >{{ '' }}</span
                        >
                        <span v-else class="text-xs text-gray-600">{{
                          product.salePrice | formatPrice
                        }}</span>
                      </div>
                    </button>
                  </div>
                </div>
              </div>

              <div class="border-b pb-8">
                <ul class="flex flex-row justify-end">
                  <div class="flex-column">
                    <li
                      class="font-semibold text-sm mt-2 pr-2 whitespace-no-wrap"
                    >
                      Subtotal:
                    </li>
                    <li
                      class="font-semibold text-sm mt-2 pr-2 whitespace-no-wrap"
                    >
                      Discount:
                    </li>
                    <li
                      class="font-semibold text-sm mt-2 pr-2 whitespace-no-wrap"
                    >
                      Total:
                    </li>
                  </div>
                  <div class="flex-column">
                    <li class="font-semibold text-sm mt-2">
                      {{ subtotal | formatPrice }}
                    </li>
                    <li class="font-semibold text-sm mt-2">
                      {{ calculatedDiscount | formatPrice }}
                    </li>
                    <li class="font-semibold text-sm mt-2">
                      {{ total | formatPrice }}
                    </li>
                  </div>
                </ul>
              </div>

              <div class="overflow-x-scroll" v-if="invoice.type.name === 'mbf'">
                <trac-custom-header class="mt-8">
                  <template slot="leading">Milestones</template>
                  <template slot="trailing"></template>
                </trac-custom-header>

                <table class="w-full table-auto">
                  <thead class="bg-blue-100">
                    <tr class="text-left text-xs mt-3 font-semibold uppercase">
                      <th
                        class="whitespace-no-wrap text-left px-4 md:w-5/12 py-3"
                      >
                        Milestone
                      </th>
                      <th
                        class="whitespace-no-wrap text-left px-4 md:w-4/12 py-3"
                      >
                        Amount
                      </th>
                      <th
                        class="whitespace-no-wrap text-left px-4 md:w-3/12 py-3"
                      >
                        Due Date
                      </th>
                      <th
                        class="whitespace-no-wrap text-left px-4 md:w-5/12 py-3"
                      ></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(milestone, index) in invoice.milestones"
                      :key="index"
                      class="text-left text-xs my-3"
                      :class="index % 2 === 0 ? '' : 'bg-gray-100'"
                    >
                      <td class="py-2 px-4 whitespace-no-wrap">
                        {{ milestone.title }}
                      </td>
                      <td class="py-2 px-4 whitespace-no-wrap">
                        {{ milestone.amount | formatPrice(false) }}
                      </td>
                      <td class="py-2 px-4 whitespace-no-wrap">
                        {{ moment(milestone.due_date).format('Do MMM YYYY') }}
                      </td>
                      <td class="py-2 px-4 whitespace-no-wrap">
                        <div class="flex justify-end">
                          <button
                            @click="editMilestone(milestone)"
                            class="bg-transparent text-xs my-2 mx-4 text-primaryGray"
                          >
                            Edit
                          </button>
                          <button
                            @click="removeMilestone(milestone)"
                            class="bg-transparent text-xs my-2 mx-4 text-red-600"
                          >
                            Delete
                          </button>
                        </div>
                      </td>
                    </tr>
                    <tr class="">
                      <td class="pl-8 cursor-pointer py-3">
                        <span
                          @click="addMilestoneModalCtrl = true"
                          class="text-xs font-bold text-primaryBlue uppercase whitespace-no-wrap"
                          >Add New Milestone +</span
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="mt-8" data-test="preferred-payment-method">
                <trac-custom-header>
                  <template slot="leading">Preferred payment</template>
                  <template slot="trailing"> Method</template>
                </trac-custom-header>

                <div
                  class="grid md:flex flex-row mt-8"
                  v-if="currentUserBusinessDetails"
                >
                  <trac-checkbox
                    data-test="dynamic-account"
                    v-if="
                      authAgentDetails.payment_option.includes('online_payment')
                    "
                    :isChecked="
                      invoice.payment_method.includes('online_payment')
                    "
                    @check="togglePaymentMethod"
                    value="online_payment"
                    class="m-2"
                    ><span class="text-sm pl-2"
                      >Online Payment</span
                    ></trac-checkbox
                  >
                  <trac-checkbox
                    data-test="bank-transfer-button"
                    v-if="
                      authAgentDetails.payment_option.includes('bank_transfer')
                    "
                    :isChecked="
                      invoice.payment_method.includes('bank_transfer')
                    "
                    class="m-2"
                    @check="togglePaymentMethod"
                    value="bank_transfer"
                    ><span class="text-sm pl-2"
                      >Dynamic Account</span
                    ></trac-checkbox
                  >
                  <trac-checkbox
                    data-test="preferred-payment-method"
                    v-if="
                      authAgentDetails.payment_option.includes(
                        'own_bank_account'
                      )
                    "
                    :isChecked="
                      invoice.payment_method.includes('own_bank_account')
                    "
                    class="m-2"
                    @check="togglePaymentMethod"
                    value="own_bank_account"
                    ><span class="text-sm pl-2"
                      >Bank Transfer</span
                    ></trac-checkbox
                  >
                </div>
                <div class="mt-8">
                  <trac-textarea
                    v-model="invoice.notes.text"
                    placeholder="Notes"
                  ></trac-textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-row gap-5 mt-8 justify-end mb-4">
          <trac-button
            data-test="send-invoice-button"
            class="uppercase"
            @button-clicked="openSendInvoiceModal"
            >Send Invoice</trac-button
          >
          <trac-button
            data-test="save-invoice-button"
            class="uppercase"
            @button-clicked="invoice._id ? updateInvoice() : saveInvoice()"
            >{{ invoice._id ? 'Update Invoice' : 'Save Invoice' }}</trac-button
          >
        </div>
      </form>
    </main>
  </div>
</template>

<script>
import AdvDropdown from '../../offline-module/components/advDropdownFilter.vue';

import {
  DELETE_LOCAL_DB_DATA,
  GET_LOCAL_DB_DATA,
  GET_USER_BUSINESS_DATA,
  GET_USER_BUSINESS_ID,
  GET_USER_DATA,
  SAVE_LOCAL_DB_DATA,
} from '../../browser-db-config/localStorage';
import { GENERATE_RANDOM_ID } from './../../browser-db-config/utilityMethods';
import moment from 'moment';
import { mapActions } from 'vuex';

export default {
  name: 'NewInvoice',
  components: {
    AdvDropdown,
  },
  data() {
    return {
      moment,
      isLoading: false,
      updateInvoiceFlag: false,
      selectInvoiceItemsTypeModalCtrl: false,
      alertPaymentMethodModalCtrl: false,
      sendInvoiceModalCtrl: false,
      addMilestoneModalCtrl: false,
      oneOffItemModalCtrl: false,
      productVariantModalCtrl: false,
      productInLibraryDropdown: false,
      invoice: null,
      currentUserBusinessDetails: null,
      filteredVarieties: [],
      filteredProducts: [],
      selectedVariantProduct: null,
      stores: [],
      discountTypes: [
        { name: 'Percentage', value: 'percentage' },
        { name: 'Amount', value: 'amount' },
        { name: 'None', value: 'none' },
      ],
      discountAmount: 0,
      customer: null,
      loadedInvoice: null,
      customers: [],
      filteredCustomers: [],
      oneOffProduct: {
        name: '',
        unit_price: '',
        quantity: '',
        is_custom: true,
      },
      sendInvoiceInfo: {
        recipients: '',
        subject: '',
        message: '',
        sendToMyself: false,
      },
      createMilestone: {
        title: '',
        amount: '',
        due_date: new Date().toISOString(),
      },
      invoiceTerms: [
        { description: 'Pay before fulfilment', name: 'pbf' },
        { description: 'Pay after fulfilment', name: 'paf' },
        { description: 'Milestone based fulfilment', name: 'mbf' },
      ],
      products: [],
    };
  },
  destroyed() {
    if (this.invoice._id) {
      // SAVE_LOCAL_DB_DATA("invoice-details", this.loadedInvoice);
    }
  },
  computed: {
    sortCustomersNames() {
      return this.filteredCustomers.sort((a, b) =>
        a.customerFullName.toLowerCase() > b.customerFullName.toLowerCase()
          ? 1
          : -1
      );
    },
    invoiceDateSeleced() {
      if (this.invoice.created_at) {
        this.invoiceDate = new Date(
          this.invoice.created_at
        ).toLocaleDateString();
        return new Date(this.invoice.created_at).toLocaleDateString();
      } else {
        return new Date().toLocaleDateString();
      }
    },
    createMilestoneDueDate() {
      return new Date(this.createMilestone.due_date).toLocaleDateString();
    },
    dueDateSelected() {
      if (this.invoice.due_date) {
        this.dueDate = new Date(this.invoice.due_date).toLocaleDateString();
        return new Date(this.invoice.due_date).toLocaleDateString();
      } else {
        return new Date().toLocaleDateString();
      }
    },
    isDiscountFieldDisabled() {
      return this.invoice.invoiceDiscountType.value === 'none';
    },
    sumAllMilestones() {
      return this.invoice.milestones.reduce(
        (total, item) => total + item.amount,
        0
      );
    },
    sumInvoiceProducts() {
      if (this.invoice) {
        this.updateInvoiceAmount();
      }

      return this.invoice
        ? this.invoice.items.reduce(
            (total, item) => total + item.unit_price * item.quantity,
            0
          )
        : 0;
    },
    subtotal() {
      return this.sumInvoiceProducts;
    },
    calculatedDiscount() {
      if (this.invoice.invoiceDiscountType.name === 'Percentage') {
        return (this.discountAmount / 100) * this.subtotal;
      } else {
        return this.discountAmount;
      }
    },
    total() {
      return this.subtotal - this.calculatedDiscount;
    },
  },
  mounted() {
    if (document.querySelector('#inputFocus')) {
      document.querySelector('#inputFocus').addEventListener('click', (e) => {
        e.target.blur();
      });
    }
  },
  async created() {
    this.isLoading = true;

    this.loadedInvoice = GET_LOCAL_DB_DATA('invoice-details');
    this.updateInvoiceFlag = this.loadedInvoice ? true : false;
    await this.fetchAllStores();
    await this.fetchAllCustomers();
    this.getBusinessDetails();

    // Check if it's from update for company info
    if (GET_LOCAL_DB_DATA('invoice-details-for-profile-update')) {
      this.invoice = GET_LOCAL_DB_DATA('invoice-details-for-profile-update');
    }

    if (this.updateInvoiceFlag) {
      if (this.loadedInvoice._id) {
        await this.fetchInvoice(this.loadedInvoice._id);
      } else {
        this.invoice = this.loadedInvoice;
        if (this.loadedInvoice.customer) {
          this.selectCustomer(this.loadedInvoice.customer);
        }
      }
    } else {
      this.setupInvoice();
    }

    this.invoice = {
      ...this.invoice,
      invoiceDiscountType: {
        name: 'Percentage',
        value: 'percentage',
      },
      discountAmount: 0,
    };

    this.sendInvoiceInfo.subject = `Invoice from ${this.currentUserBusinessDetails.name}`;

    await this.fetchAllOfflineProducts();

    this.isLoading = false;
  },
  methods: {
    ...mapActions(['FETCH_PRODUCTS_SYNC']),
    gotoPage(path) {
      console.log('path requested: ', path);
      this.$router.push({ name: path });
    },
    openBusinessPortal() {
      SAVE_LOCAL_DB_DATA('invoice-details-for-profile-update', this.invoice);
      this.$router.push({ name: 'CompanyInfo' });
    },
    triggerFocusInput() {
      if (this.invoice.title.toLowerCase() === 'invoice') {
        this.invoice.title = '';
      }
      document.querySelector('#inputFocus').removeAttribute('disabled');
      document.querySelector('#inputFocus').focus();
    },
    setInvoiceTitle() {
      if (this.invoice.title === '') {
        this.invoice.title = 'Invoice';
      }
      document.querySelector('#inputFocus').setAttribute('disabled', true);
    },
    addCustomerOnTheFly() {
      const payload = {
        origin: {
          name: 'Add Invoice',
        },
        invoice: this.invoice,
      };

      SAVE_LOCAL_DB_DATA('create-customer-on-the-fly', payload);
      this.$router.push({ name: 'AddCustomer' });
    },
    removeProduct(product) {
      if (product.is_custom) {
        this.invoice.items = this.invoice.items.filter(
          (item) => item.client_id !== product.client_id
        );
      } else {
        this.invoice.items = this.invoice.items.filter(
          (item) => item.id !== product.id
        );
      }
    },
    updateInvoiceAmount() {
      this.invoice.balance = {
        total: {
          amount: this.invoice.items.reduce(
            (total, item) => total + item.unit_price * item.quantity,
            0
          ),
          currency: '',
        },
      };
    },
    togglePaymentMethod(e) {
      if (!e.checked) {
        this.invoice.payment_method = this.invoice.payment_method.filter(
          (pm) => pm !== e.value
        );
      } else {
        this.invoice.payment_method.push(e.value);
        if (e.value === 'online_payment') {
          // this.alertPaymentMethodModalCtrl = true;
        }
      }
    },
    dismissModal(e) {
      if (!e.target.classList.contains('drop-down-hold')) {
        this.productInLibraryDropdown = false;
      }
    },
    searchProducts(e) {
      const searchedInput = e ? e.target.value : '';
      this.filteredProducts = this.products.filter((product) =>
        product.title.toLowerCase().includes(searchedInput.toLowerCase())
      );
    },
    searchVariant(e) {
      const searchedInput = e ? e.target.value : '';
      if (this.selectedVariantProduct) {
        this.filteredVarieties = this.selectedVariantProduct.filter((variant) =>
          variant.title.toLowerCase().includes(searchedInput.toLowerCase())
        );
      }
    },
    addMiletone() {
      if (this.createMilestone.client_id) {
        this.invoice.milestones.forEach((mile) => {
          if (mile.client_id === this.createMilestone.client_id) {
            mile = this.createMilestone;
          }
        });
      } else {
        if (!this.invoice.milestones) {
          this.invoice = { ...this.invoice, milestones: [] };
        }
        this.invoice.milestones.push({
          ...this.createMilestone,
          client_id: GENERATE_RANDOM_ID(),
        });
      }
      this.dismissAddMilestoneModal();
    },
    showOneOffForm() {
      this.selectInvoiceItemsTypeModalCtrl = false;
      this.oneOffItemModalCtrl = true;
    },
    showLiraryProducts() {
      this.selectInvoiceItemsTypeModalCtrl = false;
      this.productInLibraryDropdown = true;
    },
    filterCustomersList(input) {
      this.filteredCustomers = this.customers.filter((customer) => {
        return customer.customerFullName
          .toLowerCase()
          .includes(input.toLowerCase());
      });
    },
    removeMilestone(milestone) {
      if (confirm('Are you sure you want to remove this milestone')) {
        // Remove Milestone
        this.invoice.milestones = this.invoice.milestones.filter(
          (mile) => mile.client_id !== milestone.client_id
        );
      }
    },
    dismissAddMilestoneModal() {
      this.createMilestone = {
        title: '',
        amount: '',
        due_date: new Date().toISOString(),
      };

      this.addMilestoneModalCtrl = false;
    },
    editMilestone(milestone) {
      this.createMilestone = milestone;
      this.addMilestoneModalCtrl = true;
    },
    specificProductStoreStock(product, variant) {
      let specificStore = null;
      if (product.hasVariant) {
        product.variations.forEach((prodVariant) => {
          prodVariant.storeStock.forEach((ss) => {
            if (
              ss.storeID === this.invoice.store_id &&
              prodVariant.barcode === variant.barcode
            ) {
              specificStore = ss;
            }
          });
        });
      } else if (product.client_id) {
      } else {
        specificStore = product.store_stock?.find((ss) => {
          return ss.storeID === this.invoice.store_id;
        });
      }

      if (specificStore) {
        return specificStore;
      } else if (product.client_id) {
      } else if (product.manage_stock) {
        // alert("Error: Store stock unassigned.");
        // this.showAlert("trac-alert", {
        //   message: "Error: Store stock unassigned.",
        // });
        return { stockQuantity: 0 };
      }
      return { stockQuantity: 0 };
    },
    addProductToCart(product) {
      if (product.hasVariant) {
        this.selectedVariantProduct = product.variations;
        this.filteredVarieties = product.variations;
        this.productVariantModalCtrl = true;
      } else if (product.client_id) {
      } else {
        const addedProduct = this.invoice.items?.find((prodItem) => {
          if (prodItem.id === product.id) {
            // if (
            //   product.manageStock &&
            //   this.specificProductStoreStock(product).stockQuantity ===
            //     prodItem.quantity
            // ) {
            //   // alert(`Can't exceed amount in ${this.invoice.store_name}.`);
            //   this.showAlert("trac-alert", {
            //     message: `Can't exceed amount in ${this.invoice.store_name}.`,
            //   });
            //   return prodItem;
            // }
            prodItem.quantity += 1;
            prodItem.total_amount = prodItem.unit_price * prodItem.quantity;
            return prodItem;
          }
        });

        if (addedProduct) {
          return;
        }

        this.invoice.items.push({
          description: product.description,
          id: product.id,
          name: product.title,
          store_stock: product.storeStock,
          manage_stock: product.manageStock,
          quantity: 1,
          total_amount: product.salePrice,
          unit_price: product.salePrice,
        });
        // Remove variant modal
        this.selectedVariantProduct = null;
        this.productVariantModalCtrl = false;
      }
      console.log(this.invoice.items, '<>added');

      this.searchProducts();
      this.searchVariant();
    },
    addOneOffItem() {
      this.invoice.items.push({
        client_id: GENERATE_RANDOM_ID(),
        ...this.oneOffProduct,
      });
      this.oneOffProduct = {
        name: '',
        unit_price: '',
        quantity: '',
        is_custom: true,
        client_id: GENERATE_RANDOM_ID(),
      };
      this.oneOffItemModalCtrl = false;
    },
    openSendInvoiceModal() {
      if (this.invoice.client === '') {
        this.showAlert('trac-alert', {
          message: 'Please assign invoice to a customer.',
        });
        return;
      } else if (this.invoice.type.name === '') {
        this.showAlert('trac-alert', {
          message: 'Please select the terms of the invoice.',
        });
        return;
      } else if (this.invoice.items.length === 0) {
        this.showAlert('trac-alert', { message: 'Please add product.' });
        return;
      } else if (
        this.invoice.type.name === 'mbf' &&
        this.invoice.milestones.length < 2
      ) {
        this.showAlert('trac-alert', {
          message: 'Please add at least two milestones.',
        });
        return;
      } else if (
        this.invoice.type.name === 'mbf' &&
        this.sumAllMilestones !== this.sumInvoiceProducts
      ) {
        this.showAlert('trac-alert', {
          message: 'Sum of milestones must be equal to sum of products',
        });
        return;
      }

      this.selectCustomer(this.customer);
      this.sendInvoiceModalCtrl = true;
    },
    async sendInvoice() {
      this.isLoading = true;
      // Setup emails
      let emails = this.sendInvoiceInfo.recipients.split(',');

      emails = emails.map((email) => email.trim());

      let res = {};

      if (this.updateInvoiceFlag) {
        res = await this.setupPayload('UPDATE_INVOICE');
        // await this.$store.dispatch("UPDATE_INVOICE", this.invoice);
      } else {
        res = await this.setupPayload('CREATE_INVOICE');
        // await this.$store.dispatch("CREATE_INVOICE", this.invoice);
        // this.$store.getters["GET_INVOICE_RES"];

        // // this.$store.commit("SET_UP_INVOICE_RES", null);

        if (res.status) {
          SAVE_LOCAL_DB_DATA('invoice-details', {
            ...res.data.invoice,
            customer: this.customer,
          });
          // this.showAlert("trac-alert", { message: res.message });
          // this.$router.replace({ name: "InvoiceDetails" });
        } else {
          // alert(res.error || res.message);
          this.showAlert('trac-alert', { message: res.error || res.message });
        }
      }
      // const res = this.$store.getters["GET_INVOICE_RES"];

      // this.$store.commit("SET_UP_INVOICE_RES", null);

      if (res.status) {
        for (let i = 0; i < emails.length; i++) {
          const invoiceEmail = {
            invoice: res.data.invoice._id,
            email: emails[i],
            subject:
              this.sendInvoiceInfo.subject === ''
                ? `Invoice from ${this.currentUserBusinessDetails.name}`
                : this.sendInvoiceInfo.subject,
            note: this.sendInvoiceInfo.message,
            // payment_option: this.invoice.payment_method,
            payment_option: '',
            send_to_self: this.sendInvoiceInfo.sendToMyself,
          };
          await this.$store.dispatch('SEND_INVOICE', invoiceEmail);
        }

        const sendRes = this.$store.getters['GET_INVOICE_RES'];
        this.showAlert('trac-alert', {
          message: sendRes.error || sendRes.message,
        });

        this.$store.commit('SET_UP_INVOICE_RES', null);
        SAVE_LOCAL_DB_DATA('invoice-details', {
          ...res.data.invoice,
          customer: this.customer,
        });
        this.$router.replace({ name: 'InvoiceDetails' });
      } else {
        // alert(res.error || res.message);
        this.showAlert('trac-alert', { message: res.message });
      }
      this.isLoading = false;
    },
    updateDiscountInputs(amount) {
      if (this.invoice.invoiceDiscountType === 'PERCENTAGE') {
        this.invoice.discountPercentage = amount;
        this.invoice.discountAmount = '';
      } else if (this.invoice.invoiceDiscountType === 'AMOUNT') {
        this.invoice.discountAmount = amount;
        this.invoice.discountPercentage = '';
      }
    },
    checkValidForm() {
      let isFormValid = true;

      if (this.invoice.client === '') {
        isFormValid = false;
        this.showAlert('trac-alert', {
          message: 'Please assign invoice to a customer.',
        });
      } else if (this.invoice.type.name === '') {
        isFormValid = false;
        this.showAlert('trac-alert', {
          message: 'Please select the terms of the invoice.',
        });
      } else if (this.invoice.items.length === 0) {
        isFormValid = false;
        this.showAlert('trac-alert', { message: 'Please add product.' });
      } else if (
        this.invoice.type.name === 'mbf' &&
        this.invoice.milestones.length < 2
      ) {
        isFormValid = false;
        this.showAlert('trac-alert', {
          message: 'Please add at least two milestones.',
        });
      } else if (
        this.invoice.type.name === 'mbf' &&
        this.sumAllMilestones !== this.sumInvoiceProducts
      ) {
        isFormValid = false;
        this.showAlert('trac-alert', {
          message: 'Sum of milestones must be equal to sum of products.',
        });
      }

      return isFormValid;
    },
    showAlert(alertType, { message }) {
      console.log(message);
      alert(message);
      console.log(message);
    },
    async setupPayload(invoiceAction) {
      const validForm = this.checkValidForm();
      let res = {};

      if (validForm) {
        this.isLoading = true;
        const payload = {
          ...this.invoice,
          invoice_date: this.invoice.created_at,
          discount: {
            type: this.invoice.invoiceDiscountType.value,
            value: this.discountAmount,
          },
        };

        delete payload.created_at;

        res = await this.$store.dispatch(invoiceAction, payload);
      }
      return res;
    },
    async saveInvoice() {
      const res = await this.setupPayload('CREATE_INVOICE');
      // this.$store.getters["GET_INVOICE_RES"];

      this.$store.commit('SET_UP_INVOICE_RES', null);

      if (res.status) {
        SAVE_LOCAL_DB_DATA('invoice-details', {
          ...res.data.invoice,
          customer: this.customer,
        });
        this.showAlert('trac-alert', { message: res.message });
        this.$router.replace({ name: 'InvoiceDetails' });
      } else if (res.error || res.message) {
        // alert(res.error || res.message);
        this.showAlert('trac-alert', { message: res.error || res.message });
      }
      this.isLoading = false;
    },
    async updateInvoice() {
      const res = await this.setupPayload('UPDATE_INVOICE');
      // this.$store.getters["GET_INVOICE_RES"];

      this.$store.commit('SET_UP_INVOICE_RES', null);

      if (res.status) {
        await this.$store.dispatch('REFRESH_ALL_INVOICES');
        SAVE_LOCAL_DB_DATA('invoice-details', {
          ...res.data.invoice,
          customer: this.customer,
        });
        this.showAlert('trac-alert', { message: res.message });
        this.$router.back();
      } else {
        // alert(res.error || res.message);
        this.showAlert('trac-alert', { message: res.message });
      }
      this.isLoading = false;
    },
    async fetchInvoice(invoiceID) {
      await this.$store.dispatch('FETCH_INVOICE', invoiceID);
      const res = this.$store.getters['GET_INVOICE_RES'];

      this.$store.commit('SET_UP_ALL_INVOICES', null);

      if (res.status) {
        this.invoice = res.data.invoice;
        this.invoice.items = this.invoice.items.map((item) => {
          return {
            ...item,
            store_stock: this.stores.map((store) => {
              return { ...store, stock: 0, store_id: store._id };
            }),
          };
        });

        if (!this.invoice.milestones) {
          this.invoice = { ...this.invoice, milestones: [] };
        }

        if (this.customers) {
          this.customer = this.customers.find(
            (cust) => cust._id === this.invoice.client
          );
        }

        this.filteredCustomers = this.customers;
      } else {
        // alert(res.message);
        this.showAlert('trac-alert', { message: res.message });
      }
    },
    getSpecificStore(prod) {
      return (
        prod.storeStock.find((ss) => ss.storeID === this.invoice.store_id) || {}
      );
    },
    async fetchAllOfflineProducts() {
      // await this.$store.dispatch("FETCH_ALL_PRODUCTS");
      // const res = this.$store.getters["GET_ALL_PRODUCT"];

      const resp = await this.FETCH_PRODUCTS_SYNC();

      // const res = {
      //   items: (await this.$GlobalOfflineManager.getAll("products")) || [],
      // };

      // if (true) {
      this.products = resp.data.products || [];
      // this.filteredProducts = this.products;

      // Remove products that are sold out
      this.filteredProducts = this.products;
      // .filter((prod) => {
      //   if (prod.hasVariant) {
      //     prod.variations = prod.variations.filter(
      //       (va) =>
      //         (va.manageStock && this.getSpecificStore(va).stockQuantity > 0) ||
      //         !va.manageStock
      //     );
      //     return prod;
      //   } else {
      //     return (
      //       (prod.manageStock &&
      //         this.getSpecificStore(prod).stockQuantity > 0) ||
      //       !prod.manageStock
      //     );
      //   }
      // });

      // }
      // else {
      //   // alert(res.message || "");
      //   this.showAlert("trac-alert", {
      //     message: res.message | "Error: Error with network.",
      //   });
      // }
    },
    async fetchAllProducts() {
      await this.$store.dispatch('FETCH_ALL_PRODUCTS');
      const res = this.$store.getters['GET_ALL_PRODUCT'];

      if (res.status) {
        this.products = res.items || [];
        // this.filteredProducts = this.products;

        // Remove products that are sold out
        this.filteredProducts = this.products.filter((prod) => {
          if (prod.hasVariant) {
            prod.variations = prod.variations.filter(
              (va) =>
                (va.manageStock &&
                  this.getSpecificStore(va).stockQuantity > 0) ||
                !va.manageStock
            );
            return prod;
          } else {
            return (
              (prod.manageStock &&
                this.getSpecificStore(prod).stockQuantity > 0) ||
              !prod.manageStock
            );
          }
        });
      } else {
        // alert(res.message || "");
        this.showAlert('trac-alert', {
          message: res.message | 'Error: Error with network.',
        });
      }
    },
    async fetchAllStores() {
      await this.$store.dispatch('FETCH_ALL_STORES');
      const res = this.$store.getters['GET_ALL_STORES'];
      if (res.status) {
        this.stores = res.data;
      } else {
        // alert(res.message);
        this.showAlert('trac-alert', { message: res.message });
      }
    },
    async fetchAllCustomers() {
      // Fetch all customers
      await this.$store.dispatch('FETCH_ALL_CUSTOMERS');
      const res = this.$store.getters['GET_ALL_CUSTOMERS'];

      if (res.status) {
        const customersData = res.data ? res.data.items || [] : [];

        this.customers = customersData.map((customer) => {
          return {
            ...customer,
            customerFullName: customer.first_name + ' ' + customer.last_name,
          };
        });

        this.filteredCustomers = this.customers;
      }
    },
    getBusinessDetails() {
      this.currentUserBusinessDetails = GET_USER_BUSINESS_DATA();
      this.authAgentDetails = GET_USER_DATA();
    },
    selectDiscountType(option) {
      this.invoice.invoiceDiscountType = option;

      if (option.value === 'none') {
        this.invoice.discountAmount = 0;
        this.invoice.discountPercentage = 0;
      }
    },
    async selectStoreOption(option) {
      this.invoice.store_id = option._id;
      this.invoice.store_name = option.name;
      await this.fetchAllOfflineProducts();
    },
    selectCustomer(option) {
      // if (option.is_offline_data) {
      //   return;
      // }
      this.customer = option;
      this.invoice.client = option._id;
      this.sendInvoiceInfo.recipients = option.email;
      this.sendInvoiceInfo.message = `Hi, ${
        option.first_name + ' ' + option.last_name
      }.\nPlease find attached invoice for a subtotal of ${this.formatPrice(
        this.sumInvoiceProducts
      )}.\nYou have received a discount of ${this.formatPrice(
        this.calculatedDiscount
      )}.\nYour invoice total is ${this.formatPrice(
        this.total
      )}.\nThank you.\n\n${this.currentUserBusinessDetails.name}.`;
    },
    // Format balance
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(',', '.');
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    selectTermsOption(option) {
      this.invoice.type = option;
    },
    setupInvoice() {
      this.invoice = {
        balance: {
          total: {
            amount: this.sumInvoiceProducts,
            currency: '',
          },
        },
        business: GET_USER_BUSINESS_ID(),
        client: '',
        created_at: new Date().toISOString(),
        due_date: new Date().toISOString(),
        items: [],
        milestones: [].map((m) => {
          return { ...m, client_id: GENERATE_RANDOM_ID() };
        }),
        notes: { text: '' },
        payment_method: [],
        payment_option: '',
        store_id: this.stores[0]._id,
        store_name: this.stores[0].name,
        title: 'Invoice',
        type: {
          description: '',
          name: '',
        },
      };
    },
    inputInvoiceItemQty(e, item) {
      const value = e.target.value;
      if (
        item.quantity > this.specificProductStoreStock(item)?.stockQuantity &&
        item.manage_stock
      ) {
        item.quantity = this.specificProductStoreStock(item)?.stockQuantity;
      }
    },
    handleQty(type, product) {
      this.invoice.items.forEach((prod) => {
        if (type === 'increase' && prod.id === product.id) {
          if (
            (prod.manage_stock &&
              product.quantity <
                this.specificProductStoreStock(prod)?.stockQuantity) ||
            !prod.manage_stock
          ) {
            prod.quantity += 1;
          } else {
            this.showAlert('trac-alert', {
              message: `Can't exceed amount in ${this.invoice.store_name}.`,
            });
          }
        } else if (type === 'decrease' && prod.id === product.id) {
          if (prod.quantity > 1) {
            prod.quantity -= 1;
          }
        }
        prod.amount = prod.unit_price * prod.quantity;
      });
    },
  },
};
</script>

<style scoped></style>
